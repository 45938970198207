import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/post-layout.js";
import { HashLink } from '../components/link';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`10 truths to keep in mind on every product effort:`}</p>
    <ol>
      <li parentName="ol">{`The job of product manager is to discover a product that is valuable, usable and feasible.`}</li>
      <li parentName="ol">{`Product discovery is a collaboration between the product manager, interaction designer and software architect.`}</li>
      <li parentName="ol">{`Engineering is important and difficult, but UX design is even more important, and usually more difficult.`}</li>
      <li parentName="ol">{`Engineers are typically very poor at UX design - engineers think in terms of implementation models, but users think in terms of conceptual models.`}</li>
      <li parentName="ol">{`UX design means both interaction design and visual design (and for hardware-based devices, industrial design).`}</li>
      <li parentName="ol">{`Functionality (product requirements) and UX design are inherently intertwined.`}</li>
      <li parentName="ol">{`Product ideas must be tested - early and often - on actual target users in order to come up with a product that is valuable and usable.`}</li>
      <li parentName="ol">{`We need a high-fidelity prototype so we can quickly, easily and frequently test our ideas on real users using a realistic UX.`}</li>
      <li parentName="ol">{`The job of the product manager is to identify the MVP that meets the objectives - valuable, usable and feasible - minimizing time to market and user complexity.`}</li>
      <li parentName="ol">{`Once this MVP has been discovered and validated, it is not something that you can piecemeal and expect the same results.`}</li>
    </ol>
    <h2><HashLink id="staffing" to="/inspired#staffing" mdxType="HashLink">{`Staffing`}</HashLink></h2>
    <p>{`There should only be one product manager accountable for a product.`}</p>
    <p>{`When interviewing prospective product managers, it's helpful to not look so much for what they already know but for each of their products, what did they need to learn, how long did it take them and how did they apply that knowledge?`}</p>
    <p>{`Every new product manager needs roughly three months of hard learning before you can entrust them with the responsibility of guiding a product.`}</p>
    <ul>
      <li parentName="ul">{`immerse with target users/customers`}</li>
      <li parentName="ul">{`get educated on the relevant technologies`}</li>
      <li parentName="ul">{`study the market/competitive landscape`}</li>
    </ul>
    <blockquote>
      <p parentName="blockquote">{`If you micromanage your product managers, they will not step up and take ownership the way you need them to.`}</p>
    </blockquote>
    <p>{`It is critical to have `}<em parentName="p">{`someone`}</em>{` that the engineering team can get fast answers/feedback from. If not the product manager, someone close to them.`}</p>
    <p>{`Ideally, the product organization includes the design team. The interaction between product management and UX design absolutely needs to be as close as possible.`}</p>
    <p>{`Design-related roles (possible to find people that can handle more than one):`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Interaction Design`}</strong>{` - these people are responsible for developing a deep understanding of the target users and coming up with the tasks, navigation and flow that are both valuable and usable. Generally, the interaction designer maps product requirements to a design represented by wireframes and passes them to the visual designer.`}</li>
      <li parentName="ul"><strong parentName="li">{`Visual Design`}</strong>{` - these people put the flesh on the wireframe and create the actual pages and user interface look and feel. This includes everything from the precise layout, colors and fonts but, more importantly, the visual design communicates and evokes emotion in the product (which is far more important than you may think).`}</li>
      <li parentName="ul"><strong parentName="li">{`Rapid Prototyping`}</strong>{` - the prototypes work to capture the ideas of the product manager and designers into a prototype that can be tested on real users and iterated upon.`}</li>
      <li parentName="ul"><strong parentName="li">{`Usability Testing`}</strong>{` - this person specializes in research and analysis of the users, evaluating whether products or prototypes allow a given user to easily achieve objectives. It includes recruiting appropriate test subjects, administering the tests, evaluating the results and recommending alternatives.`}</li>
    </ul>
    <p>{`Given the option it's a better choice to outsource QA vs design.`}</p>
    <p>{`If you're trying to create inspiring products, for most professional positions, outsourcing should not be about cost savings - it should be about assembling the right people for the product. This might mean hiring remotely.`}</p>
    <h2><HashLink id="discovery" to="/inspired#discovery" mdxType="HashLink">{`Product discovery`}</HashLink></h2>
    <p>{`Product Opportunity Assessment - 10 fundamental questions for product managers`}</p>
    <ol>
      <li parentName="ol">{`Exactly what problem will this solve? (value proposition)`}</li>
      <li parentName="ol">{`For whom do we solve that problem? (target market)`}</li>
      <li parentName="ol">{`How big is the opportunity? (market size)`}</li>
      <li parentName="ol">{`How will we measure success? (metrics/revenue strategy)`}</li>
      <li parentName="ol">{`What alternatives are out there now? (competitive landscape)`}</li>
      <li parentName="ol">{`Why are we best suited to pursue this? (our differentiator)`}</li>
      <li parentName="ol">{`Why now? (market window)`}</li>
      <li parentName="ol">{`How will we get this product to market? (go-to-market strategy)`}
        <ul parentName="li">
          <li parentName="ul">{`describing how the product will be sold can have very significant impact on product requirements`}</li>
        </ul>
      </li>
      <li parentName="ol">{`What factors are critical to success? (solution requirements)`}
        <ul parentName="li">
          <li parentName="ul">{`not the actual solution`}</li>
        </ul>
      </li>
      <li parentName="ol">{`Given the above, what's the recommendation? (go or no-go)`}</li>
    </ol>
    <p>{`General order of operations for discovery:`}</p>
    <ol>
      <li parentName="ol">{`Product Opportunity Assessment`}</li>
      <li parentName="ol">{`Spend quality time interviewing real users, identifying a preliminary set of requirements`}</li>
      <li parentName="ol">{`Work with a designer on a prototype`}</li>
      <li parentName="ol">{`Conduct user testing with the prototype`}</li>
      <li parentName="ol">{`Flesh out details of the use cases`}</li>
      <li parentName="ol">{`Review the prototype and spec with engineering
This process is very hard to time-box. If you rush it you run the risk of building the wrong thing. Sometimes this is needed in startups.`}</li>
    </ol>
    <p>{`UX design needs to happen before the implementation begins.`}</p>
    <p>{`Requirements for a good and useful product spec:`}</p>
    <ul>
      <li parentName="ul">{`must describe the full UX`}
        <ul parentName="li">
          <li parentName="ul">{`product requirements, user interaction design and visual design`}</li>
        </ul>
      </li>
      <li parentName="ul">{`must have a prototype to accurately represent the behavior of the software`}
        <ul parentName="li">
          <li parentName="ul">{`don't need to demo every single edge case`}</li>
        </ul>
      </li>
      <li parentName="ul">{`must communicate in a way that's digestible to the entire audience`}
        <ul parentName="li">
          <li parentName="ul">{`engineering, QA, customer service, marketing, site operations, sales and executives`}</li>
        </ul>
      </li>
      <li parentName="ul">{`must be a single source-of-truth`}</li>
      <li parentName="ul">{`must change to reflect any adjustments made since initial publication`}</li>
    </ul>
    <p>{`Unfortunately, companies often use the engineering organization to build a very, very expensive prototype and they use the live customers as unwitting test subjects.`}</p>
    <blockquote>
      <p parentName="blockquote">{`... testing your ideas with real users is probably the single most important activity in your job as product manager.`}</p>
      <p parentName="blockquote">{`Just as you wouldn't allow an engineer to ship code just because he or she believed it was good, you must test that code to be sure.`}</p>
    </blockquote>
    <p>{`The product discovery process is about answering these questions:`}</p>
    <ul>
      <li parentName="ul">{`What technologies can I apply to solve this problem in a better way?`}</li>
      <li parentName="ul">{`What should the UX be?`}</li>
    </ul>
    <p>{`Personas`}</p>
    <ul>
      <li parentName="ul">{`help frame who the product is for`}</li>
      <li parentName="ul">{`help guard against building products for ourselves`}</li>
      <li parentName="ul">{`help when deciding what should be included in a release
Personas should be prioritized. Ideally focus on one persona per release.`}</li>
    </ul>
    <p>{`Build new vs improve existing?
In reality, many products are poorly done and rather than improve a product to the point where it can generate real revenue and success, many organizations view it as easier to create a new product instead. But unless they change the way they produce that new product, they're likely going to end up with yet another under-performing product in need of improvement.`}</p>
    <p>{`Doing explicitly-defined work for a specific customer (typically in exchange for their agreement to purchase your product) is very dangerous. Short-term revenue might be nice but potentially hurting the overall customer experience outweighs the short-term gains.`}</p>
    <p>{`Always keep two versions of a product going in parallel. As soon as you start the engineering for release 1.0 then you start up the discovery for release 2.0 in parallel. Warning: you need to be careful that this approach doesn't detract from the execution work for the current project. `}</p>
    <blockquote>
      <p parentName="blockquote">{`You and your designers should always try and be one or two sprints ahead of your team.`}</p>
    </blockquote>
    <h2><HashLink id="engineering" to="/inspired#engineering" mdxType="HashLink">{`Engineering`}</HashLink></h2>
    <blockquote>
      <p parentName="blockquote">{`It doesn't matter how good your engineering team is if they are not given something worthwhile to build.`}</p>
    </blockquote>
    <p>{`At least as important as "building the thing right" is "building the right thing" (a product that is valuable, usable and feasible).`}</p>
    <p>{`It's useful to have a software architect review the progress and prototypes to ensure feasibility.`}</p>
    <p>{`Get your engineers in front of users and customers. Not only will they learn a great deal from seeing users struggle first hand, but they will get a better appreciation for the issues and their severity. This is often the inspiration for much better ideas and solutions. You can jumpstart this easily by inviting an engineer along to your prototype testing.`}</p>
    <p>{`We tend to want to think of our users as we thing of ourselves and our friends. However, the target market very likely has quite different values, priorities, perceptions, tolerances, experiences and technical understandings.`}</p>
    <h2><HashLink id="finance" to="/inspired#finance" mdxType="HashLink">{`Finance`}</HashLink></h2>
    <p>{`Understanding the economics of your product:`}</p>
    <ul>
      <li parentName="ul">{`Do you know your exact revenue model?`}</li>
      <li parentName="ul">{`Do you know how much you pay for each new customer?`}</li>
      <li parentName="ul">{`Do you know their lifetime value to the company?`}</li>
      <li parentName="ul">{`Do you know the return your product has generated for the company?`}</li>
      <li parentName="ul">{`Do you know the total costs of your product?`}</li>
    </ul>
    <p>{`Befriend someone in finance to help gain insight here. Can ask the CFO for a person they recommend.`}</p>
    <h2><HashLink id="market" to="/inspired#market" mdxType="HashLink">{`Market Research`}</HashLink></h2>
    <p>{`Try to get at least 6 happy, live, reference-able customers from your target market (you'll probably need to start with 8-10). They make the product discovery/refinement easier and their testimonials make marketing much easier. Offer the product for free until they have a product they love.`}</p>
    <p>{`If you have a ton of trouble recruiting these customers you may not be solving a problem worth solving. Usually, if the problem is important enough, prospective customers will want to participate.`}</p>
    <p>{`Tools:`}</p>
    <ul>
      <li parentName="ul">{`Customer surveys`}</li>
      <li parentName="ul">{`Site analytics`}</li>
      <li parentName="ul">{`Data mining`}
        <ul parentName="li">
          <li parentName="ul">{`site analytics, billing data, user data, etc.`}</li>
          <li parentName="ul">{`think Tableau`}</li>
        </ul>
      </li>
      <li parentName="ul">{`On-site visits`}
        <ul parentName="li">
          <li parentName="ul">{`expensive and infrequent but worth it`}</li>
          <li parentName="ul">{`plan accordingly`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Personas`}</li>
      <li parentName="ul">{`Usability testing`}</li>
      <li parentName="ul">{`Analysis of your competition`}</li>
    </ul>
    <p>{`The product manager should attend every user interview, every site visit, every usability test and every charter user program meeting that pertains directly to their product.`}</p>
    <p>{`These techniques can help answer these questions:`}</p>
    <ul>
      <li parentName="ul">{`Do you understand who your users really are?`}</li>
      <li parentName="ul">{`How are users using your product?`}</li>
      <li parentName="ul">{`Can users figure out how to use your product? Where do they stumble?`}</li>
      <li parentName="ul">{`Why do users use your product?`}</li>
      <li parentName="ul">{`What do users like about your product?`}</li>
      <li parentName="ul">{`What do you users want added to or changed in your product?`}</li>
    </ul>
    <p>{`Product `}<em parentName="p">{`marketing`}</em>{` and product `}<em parentName="p">{`management`}</em>{` are distinct and each contribute to one another. The product marketing person will be `}<em parentName="p">{`one of several key sources of input`}</em>{` to product requirements owned by the product manager. The product manager will be one of the several key sources of input to marketing messages owned by product marketing. `}</p>
    <p>{`All of Chapter 22 is great for learning about how to conduct effective usability testing.`}</p>
    <h2><HashLink id="misc" to="/inspired#misc" mdxType="HashLink">{`Misc`}</HashLink></h2>
    <blockquote>
      <p parentName="blockquote">{`Winning products come from the deep understanding of the user's needs combined with an equally deep understanding of what's just now possible.`}</p>
    </blockquote>
    <p>{`"Never tell people how to do things. Tell them what to do, and they will surprise you with their ingenuity." - General George S Patton, Jr.`}</p>
    <p>{`"Most people wander around in the dark and bitch about it being dark, instead of learning where the light switches are." - David Weiden`}</p>
    <p>{`NPS is a good metric but shouldn't be the only one.`}</p>
    <p>{`When working in a large company there are many reasons for constant change in direction:`}</p>
    <ul>
      <li parentName="ul">{`managerial hierarchies with differing incentives at different levels`}</li>
      <li parentName="ul">{`outside influence:`}
        <ul parentName="li">
          <li parentName="ul">{`competitive pressures`}</li>
          <li parentName="ul">{`changing technologies`}</li>
          <li parentName="ul">{`mergers/acquisitions`}</li>
          <li parentName="ul">{`business development deals`}</li>
          <li parentName="ul">{`budget`}</li>
          <li parentName="ul">{`staffing constraints`}</li>
        </ul>
      </li>
    </ul>
    <p>{`This is part of the cost of working at a large firm. The benefit, however, is that if you can find a way to leverage the resources of your large company, you can have a dramatic impact on the marketplace on a scale that's hard to match at a small firm.`}</p>
    <p>{`Rapid response phase - begins at launch and lasts typically a few days to a week. This is all about responding quickly to what you learn once the product has launched.`}</p>
    <p>{`More often than not, innovation is about salving an existing problem in a new way rather than solving an entirely new problem.
Watching people struggle with existing solutions is a great way to highlight innovation opportunities. This could be the product your building `}<em parentName="p">{`or a competitor's product`}</em>{`. `}</p>
    <p>{`Interesting insight on the iPhone as a product - "The Hardware Serves the Software"
We're seeing this today with folks designing hardware specifically for machine learning algorithms.`}</p>
    <p>{`Platform products need to meet the needs of all three stakeholders:`}</p>
    <ul>
      <li parentName="ul">{`The application providers - the businesses that choose to use your platform to build their solution.`}
        <ul parentName="li">
          <li parentName="ul">{`cares about pricing, licensing, quality, support, global availability, back-up plans if your company goes away, etc.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`The developers - those who work for the application providers and who write their software using your platform services.`}
        <ul parentName="li">
          <li parentName="ul">{`cares about easily creating maintainable, reliable code in the languages they want to use, working with their favorite tools and infrastructure.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`The end-users - the ones who run the application provider's product(s) and ultimately use your service.`}
        <ul parentName="li">
          <li parentName="ul">{`care about the end result`}</li>
        </ul>
      </li>
    </ul>
    <p>{`Product manager worry list:`}</p>
    <ol>
      <li parentName="ol">{`Is my product compelling to our target customer?`}</li>
      <li parentName="ol">{`Have we made this product as easy to use as humanly possible?`}</li>
      <li parentName="ol">{`Will this product succeed against the competition? Not today's competition, but the competition that will be in the market when we ship?`}</li>
      <li parentName="ol">{`Do I know customers who will really buy this product? Not the product I wish we were going to build, but what we're really going to build?`}</li>
      <li parentName="ol">{`Is my product truly differentiated? Can I explain the differentiation to a company executive in 2 minutes? To a smart customer in 1 minute? To an industry analyst in 30 seconds?`}</li>
      <li parentName="ol">{`Will the product actually work?`}</li>
      <li parentName="ol">{`Is the product a whole product? How will customers actually think about and buy the product? Is it consistent with how we plan to sell it?`}</li>
      <li parentName="ol">{`Are the product's strength consistent with what's important to our customers? Are we positioning these strengths as aggressively as possible?`}</li>
      <li parentName="ol">{`Is the product worth money? How much money? Why? Can customers get it cheaper elsewhere?`}</li>
      <li parentName="ol">{`Do I understand what the rest of the product team thinks is good about the product? Is it consistent with my own view?`}</li>
    </ol>
    <h2><HashLink id="takeaways" to="/inspired#takeaways" mdxType="HashLink">{`Overall takeaways`}</HashLink></h2>
    <blockquote>
      <p parentName="blockquote">{`Winning products come from the deep understanding of the user's needs combined with an equally deep understanding of what's just now possible.`}</p>
    </blockquote>
    <p>{`We tend to want to think of our users as we thing of ourselves and our friends. However, the target market very likely has quite different values, priorities, perceptions, tolerances, experiences and technical understandings.`}</p>
    <p>{`Prototype and test with users before starting engineering on larger initiatives.`}</p>
    <p>{`Prototypes should be required in PRDs.`}</p>
    <p>{`Start doing rapid response phases.`}</p>
    <p>{`Conducting user testing with our competitors' products can give us insight into a competitive edge.`}</p>
    <p>{`Many times customer != user.`}</p>
    <p>{`Measure to gain better visibility into our progress.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      